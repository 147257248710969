<template>
  <layout :loading="loading">
    <template v-slot:content>
      <div class="px-2 md:mx-auto mt-4 availability-reminder-container">
        <div class="flex items-center flex-wrap gap-4">
          <div class="flex items-center justify-between w-56 mr-4">
            <label class="w-16 font-semibold">{{ $t("week") }}</label>
            <t-select
              class="w-44"
              v-model="filterWeek"
              :options="weeks"
            ></t-select>
          </div>
          <div class="flex items-center justify-between w-56 mr-4">
            <label class="w-16 font-semibold">{{ $t("job_type") }}</label>
            <t-select
              class="w-44"
              v-model="filterJobType"
              :options="jobFilters"
              value-attribute="id"
              text-attribute="name"
            ></t-select>
          </div>
          <div class="flex items-center justify-between w-56">
            <label class="w-16 font-semibold">{{ $t("job") }}</label>
            <t-select
              class="w-44"
              v-model="filterJobFilterJob"
              :options="filterJobs"
              value-attribute="id"
              text-attribute="name"
            ></t-select>
          </div>
        </div>
        <t-alert
          variant="success"
          class="mt-2"
          :timeout="5000"
          :show="showSuccess"
        >
          {{ successMessage }}
        </t-alert>
        <div class="grid grid-cols-4 gap-4 mx-20 my-10">
          <div id="employee-table" class="w-11/12 col-span-3 overflow-auto">
            <p class="text-lg mt-3 mb-5">
              {{ $t("staff_without_availability") }} ({{ $t("week") }}
              {{ filterWeek }})
            </p>
            <div class="mt-5 overflow-y-scroll employees-table">
              <table
                class="
                  w-full
                  border-b border-gray-300 border-collapse
                  shadow-sm
                "
              >
                <thead class="border-l bg-gray-200 h-10 sticky top-0 header">
                  <tr>
                    <th class="text-left p-2 w-2/4">Employee</th>
                    <th class="text-left p-2 w-2/12">Phone Number</th>
                    <th class="text-center p-2 w-2/12">
                      <div
                        class="
                          flex
                          gap-4
                          justify-center
                          content-center
                          items-center
                        "
                      >
                        <div>Send SMS?</div>
                        <div>
                          <t-checkbox
                            v-model="checkAllState"
                            @change="checkAllEmployees"
                          />
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody class="bg-white divide-t divide-gray-100">
                  <tr
                    v-for="(row, index) in list"
                    :key="index"
                    class="hover:bg-gray-100"
                  >
                    <td
                      class="border-l border-b border-gray-200 p-2"
                      :class="!row.valid ? 'text-red-700' : ''"
                    >
                      <div v-if="!row.valid" class="tooltip">
                        <span class="tooltiptext"
                          ><i
                            class="fas fa-exclamation-circle text-red-500 mr-2"
                          ></i
                          >{{ $t("invalid_phone_number") }}</span
                        >
                        <p class="underline">{{ row.name }}</p>
                      </div>
                      <div v-else>
                        {{ row.name }}
                      </div>
                    </td>

                    <td class="border-l border-b border-gray-200 p-2">
                      {{ row.phone }}
                    </td>

                    <td
                      class="border-l border-b border-gray-200 text-center p-2"
                    >
                      <t-checkbox
                        name="options"
                        v-bind:id="row.id | toString"
                        v-model="row.checked"
                        @click="checkEmployee(row.id)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div id="text-reminder" class="w-full">
            <p class="text-lg mt-3 mb-5">
              {{ $t("sms_text_config") }}
            </p>
            <t-textarea
              v-model="form.textMessage"
              :variant="errors.textMessage ? 'danger' : ''"
              class="w-full"
              rows="8"
              required
            ></t-textarea>
            <div class="flex gap-4">
              <t-button
                class="button border-2 text-sm rounded-md mt-5 w-24"
                variant="primary"
                @click="saveTextConfig"
              >
                {{ $t("save") }}
              </t-button>
              <t-button
                class="button border-2 text-sm rounded-md mt-5 w-32"
                variant="success"
                :disabled="unselected"
                @click="validateConfig"
              >
                {{ $t("send_sms") }}
              </t-button>
            </div>
          </div>
        </div>
        <t-modal class="confirm-modal" v-model="showConfirmModal">
          <div class="mb-4">{{ confirmMessage }}</div>
          <template v-slot:footer>
            <div class="flex justify-end">
              <t-button
                type="button"
                class="text-sm mr-2"
                variant="primary"
                :disabled="loading"
                @click="sendSms"
              >
                {{ $t("sms_confirm") }}
              </t-button>
              <t-button
                type="button"
                class="text-sm"
                :disabled="loading"
                @click="showConfirmModal = false"
                >{{ $t("close") }}</t-button
              >
            </div>
          </template>
        </t-modal>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import { actions, getters } from "@/constants/state";
import { sendSmsTextMessage } from "@/services/availability-reminder-service";
import { updateSmsTextConfig } from "@/services/domain-service";
import { getEndWeekDuration, getWeekOptions } from "@/utils/misc";
import { filter, get, isEmpty, map, size } from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { Layout },
  data: () => ({
    loading: false,
    showSuccess: false,
    successMessage: null,
    checkAllState: false,
    showConfirmModal: false,
    list: [],
    form: {
      textMessage: null,
    },
    errors: {
      textMessage: false,
    },
  }),

  mounted() {
    this.form = {
      textMessage: get(this.domain, "sms_text_message"),
    };

    this.fetchEmployees();
  },

  methods: {
    ...mapActions({
      getEmployees: actions.AVAILABILITY_REMINDER_FETCH_EMPLOYEES_ACTION,
      setMainFilterAction: actions.SESSION_SET_MAIN_FILTER_ACTION,
      getJobFilterJobs: actions.JOBS_FETCH_JOB_FILTER_JOBS_ACTION,
    }),

    async fetchEmployees() {
      this.list = map(this.employeesList, (employee) => ({
        ...employee,
        checked: false,
      }));
    },

    checkEmployee(id) {
      this.list.find((employee) => employee.id == id).checked = true;
    },

    checkAllEmployees(checked) {
      this.list = map(this.list, (employee) => ({
        ...employee,
        checked,
      }));
    },

    validateConfig() {
      if (!isEmpty(this.selectedEmployees) && get(this.form, "textMessage")) {
        this.showConfirmModal = true;
      }
    },

    async saveTextConfig() {
      this.errors.textMessage = false;
      this.showSuccess = false;

      if (!this.form.textMessage) {
        this.errors.textMessage = true;

        return;
      }

      try {
        await updateSmsTextConfig(this.locationId, this.form);

        this.successMessage = this.$i18n.t("sms_text_config_success");
        this.showSuccess = true;
      } catch (error) {
        console.error("DEBUG", error);
      }
    },

    async sendSms() {
      this.showSuccess = false;

      if (isEmpty(this.selectedEmployees)) {
        return;
      }

      try {
        await sendSmsTextMessage({
          ids: map(this.selectedEmployees, "id"),
          from: this.mainFilter.week,
        });

        this.successMessage = this.$i18n
          .t("sms_text_email_success")
          .replace("{amount}", size(this.selectedEmployees));

        this.showSuccess = true;
      } catch (error) {
        console.error("DEBUG", error);
      }

      this.showConfirmModal = false;
    },
  },
  computed: {
    ...mapGetters({
      isAdminOrSuperUser: getters.AUTH_IS_ADMIN_OR_SUPERUSER_GETTER,
      locale: getters.SESSION_LOCALE_GETTER,
      userId: getters.AUTH_USER_ID_GETTER,
      locationId: getters.DOMAIN_LOCATION_ID_GETTER,
      employeesList: getters.AVAILABILITY_REMINDER_EMPLOYEES_GETTER,
      mainFilter: getters.SESSION_MAIN_FILTER_GETTER,
      jobFilters: getters.JOBS_JOB_FILTERS_GETTER,
      jobFilterJobs: getters.JOBS_JOB_FILTER_JOBS_GETTER,
      domain: getters.DOMAIN_GETTER,
    }),

    weeks() {
      return getWeekOptions(this.isAdminOrSuperUser);
    },

    filterJobs() {
      const jobs = [];

      jobs.push({
        name: "All Jobs",
        id: null,
      });

      this.jobFilterJobs.forEach((item) => {
        jobs.push({
          name: item.name,
          id: item.id,
        });
      });

      return jobs;
    },

    selectedEmployees() {
      return filter(this.list, function (employee) {
        return employee.checked;
      });
    },

    unselected() {
      return isEmpty(this.selectedEmployees);
    },

    confirmMessage() {
      return this.$i18n
        .t("sms_text_confirm")
        .replace("{message}", get(this.form, "textMessage", ""));
    },

    filterWeek: {
      get() {
        return this.mainFilter.week;
      },
      set(week) {
        this.setMainFilterAction({
          ...this.mainFilter,
          week,
        });
      },
    },

    filterJobType: {
      get() {
        return +this.mainFilter.jobFilterId;
      },
      set(jobFilterId) {
        this.setMainFilterAction({
          ...this.mainFilter,
          jobId: null,
          jobFilterId,
        });

        this.getJobFilterJobs({
          jobFilterId,
          locationId: this.locationId,
        });
      },
    },

    filterJobFilterJob: {
      get() {
        return +this.mainFilter.jobId;
      },
      set(jobId) {
        this.setMainFilterAction({
          ...this.mainFilter,
          jobId,
        });
      },
    },

    listFilters() {
      return this.filterWeek && this.filterJobType && this.filterJobFilterJob;
    },
  },
  filters: {
    toString(value) {
      return value.toString();
    },
  },
  watch: {
    listFilters: {
      deep: true,
      handler: async function () {
        this.loading = true;

        try {
          await this.getEmployees({
            from: this.mainFilter.week,
            until: getEndWeekDuration(this.mainFilter.week),
            jobFilterId: this.mainFilter.jobFilterId,
            jobId: this.mainFilter.jobId,
          });

          this.loading = false;
        } catch (error) {
          console.error("DEBUG: filters watch", error);
        }

        this.checkAllState = false;
        this.fetchEmployees();

        this.loading = false;
      },
    },
  },
};
</script>

<style lang="scss">
.availability-reminder-container {
  .employees-table {
    height: 700px;
    overflow-y: auto;

    .header {
      z-index: 2;
    }
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    color: black;
    background-color: #e5e7eb;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 130%;
    left: 80%;
    margin-left: -60px;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #e5e7eb transparent;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .custom-wrapper {
    width: 600px;
  }
}
</style>
